"use client";

import {
  ReadonlyURLSearchParams,
  useRouter,
  useSearchParams,
} from "next/navigation";
import LoginCard from "../components/LoginCard";
import toast from "react-hot-toast";
import { UserContext } from "../utils/user";
import { useContext, useEffect } from "react";
import PageLoading from "../components/PageLoading";
import withNoSSR from "../withNoSSR";

const LoginPage: React.FC = () => {
  const router = useRouter();
  const { storeUser } = useContext(UserContext);
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const authenticated = searchParams.has("authenticated");
  const google = searchParams.has("google");
  const verified = searchParams.has("verified");

  useEffect(() => {
    const fetchData = async () => {
      if (authenticated) {
        (async () => {
          const authResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/whoami`,
            {
              credentials: "include",
              redirect: "error",
            }
          );

          if (authResponse.status > 400) {
            toast.error("Invalid email or password");
            return false;
          }
          const user = await authResponse.json();
          await storeUser(user);

          const redirectFromLocalStorage = localStorage.getItem("redirect");
          if (redirectFromLocalStorage) {
            localStorage.removeItem("redirect");
            router.push(redirectFromLocalStorage);
            return;
          }

          if (verified) {
            toast.success("Email verified", {
              duration: 5 * 1000,
            });
            router.push("/profile");
          } else if (google) {
            router.push("/");
          } else {
            router.push("/profile#change_password");
          }
        })();
      }
    };
    fetchData();
  }, []);
  return <>{authenticated ? <PageLoading /> : <LoginCard />}</>;
};

export default withNoSSR(LoginPage);
